import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <g transform="translate(22.0000000, 23.5000000)">
          <path
            d="M 0 32.24 L 0 29.9 L 8.255 29.9 L 8.255 32.305 A 8.592 8.592 0 0 0 8.426 34.078 Q 8.656 35.167 9.194 35.992 A 4.721 4.721 0 0 0 9.848 36.79 A 5.091 5.091 0 0 0 11.934 38.001 Q 12.737 38.252 13.709 38.322 A 10.88 10.88 0 0 0 14.495 38.35 A 8.228 8.228 0 0 0 16.254 38.174 Q 17.372 37.929 18.203 37.345 A 4.629 4.629 0 0 0 18.948 36.693 A 5.35 5.35 0 0 0 20.105 34.632 Q 20.475 33.451 20.475 31.915 L 20.475 0 L 28.86 0 L 28.86 31.915 A 18.904 18.904 0 0 1 28.48 35.832 Q 27.976 38.211 26.803 40.009 A 10.256 10.256 0 0 1 25.155 41.957 A 11.964 11.964 0 0 1 20.554 44.543 Q 17.918 45.37 14.495 45.37 Q 9.523 45.37 6.178 43.659 A 11.487 11.487 0 0 1 3.77 42.023 Q 0.177 38.832 0.008 32.836 A 21.242 21.242 0 0 1 0 32.24 Z"
            fill="currentColor"
          />
        </g>
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
